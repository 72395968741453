import React from "react";
import { Link } from "gatsby";

import IconArrow from "../../svg/arrow-right.svg";
import IconWhatsup from "../../svg/whatup.svg";
import IconFacebook from "../../svg/facebook.svg";
import FlagUk from "../../svg/flag-uk.svg";
import FlagLt from "../../svg/flag-lt.svg";
import Logo from "../../svg/logo.svg";
import "./scss/footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__promo">
        <div className="footer__frame">
          <div className="footer__promo-row">
            <h2>
              Greitas ir patikimas siuntų pervežimas <br />
              Lietuva - Anglija
            </h2>
            <Link to="/registracija-siuntos/">
              Registruoti siuntą dabar
              <IconArrow />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer__content">
        <div className="footer__frame">
          <div className="footer__row">
            <div className="footer__column-logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="footer__column-btn">
              <p className="footer__address">
                46 Dore Cl, Northampton, <br />
                Northamptonshire NN3 8NJ
              </p>
              <div className="footer__buttons">
                <a className="footer__btn" href="tel:+37062518777">
                  <FlagLt />
                  +370 625 187 77
                </a>
                <a className="footer__btn" href="tel:+447872137294">
                  <FlagUk />
                  +44 78 72 1372 94
                </a>
                <a
                  className="footer__btn footer__btn--icon"
                  href="https://api.whatsapp.com/send?phone=37062518777"
                  target="_blank"
                >
                  <IconWhatsup />
                </a>
                <a
                  className="footer__btn footer__btn--icon"
                  href="https://www.facebook.com/zilvinoreisai"
                  target="_blank"
                >
                  <IconFacebook />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
