import { Link } from "gatsby";
import React from "react";
import classNames from "classnames";

import Logo from "../../svg/logo.svg";
import IconWhatsup from "../../svg/whatup.svg";
import IconFacebook from "../../svg/facebook.svg";
import IconPhone from "../../svg/phone.svg";
import "./scss/header.scss";
import downloadPdf from "../../../static/declaration-forms-CN22.pdf";

export const Header = ({
  setMenuOpened,
  menuOpened,
  menuRef,
  btnRef,
  withBackground,
}) => {
  return (
    <div
      className={classNames("header", {
        "header--with-bg": withBackground,
      })}
    >
      <div className="header__frame">
        <div className="header__row">
          <Link to="/" className="header__logo">
            <Logo />
          </Link>
          <div className="header__menu" ref={menuRef}>
            <nav className="header__nav">
              <ul className="header__nav-list">
                <li className="header__nav-item">
                  <a className="header__nav-link" href="/kainos">
                    Kainos
                  </a>
                </li>
                <li className="header__nav-item">
                  <a
                    className="header__nav-link"
                    href={downloadPdf}
                    target="_blank"
                  >
                    Siuntos deklaracija
                  </a>
                </li>
                <li className="header__nav-item">
                  <Link className="header__nav-link" to="/kontaktai">
                    Kontaktai
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="header__buttons">
              <a className="header__btn" href="tel:+37062518777">
                <IconPhone />
                +370 625 187 77
              </a>
              <a
                className="header__btn header__btn--icon"
                href="https://api.whatsapp.com/send?phone=37062518777"
              >
                <IconWhatsup />
                <span>WhatsApp</span>
              </a>
              <a
                className="header__btn header__btn--icon"
                href="https://www.facebook.com/zilvinoreisai"
                target="_blank"
              >
                <IconFacebook />
                <span>Facebook</span>
              </a>
            </div>
          </div>
          <button
            ref={btnRef}
            className="btn-toggle"
            onClick={() => setMenuOpened(!menuOpened)}
          >
            <div>
              <span />
              <span />
              <span />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
