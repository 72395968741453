import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import React from "react";

export function FormField(
  {
    label,
    type = "text",
    name,
    disabled,
    error,
    ...otherProps
  }) {
  return (
    <div className="form__field-wrapper">
      <label className="form__label" htmlFor={name}>{label}:</label>
      <input
        {...otherProps}
        className={classNames("form__field-text", {
          "have-error": error
        })}
        type={type}
        id={name}
        disabled={disabled}
        name={name}
      />
      {error && <div className="form__field-error">{error}</div>}
    </div>
  );
}
