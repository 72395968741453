import classNames from "classnames";
import React from "react";

export function FormTextarea({ label, name, disabled, error, ...otherProps }) {
  return (
    <div className="form__field-wrapper">
      <label className="form__label" htmlFor={name}>
        {label}:
      </label>
      <textarea
        className={classNames("form__textarea", {
          "have-error": error,
        })}
        id={name}
        disabled={disabled}
        {...otherProps}
      />
      {error && <div className="form__field-error">{error}</div>}
    </div>
  );
}
