import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import "../../scss/site-styles.scss";
import "./scss/layout.scss";
import useSiteMetadata from "../pageMasterLayout/queryMetadata";
import { Header } from "./Header";
import { Footer } from "./Footer";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

const MainLayout = ({
  className,
  children,
  withBackground,
  title,
  description,
}) => {
  const { title: siteTitle, description: siteDescription } = useSiteMetadata();
  const [menuOpened, setMenuOpened] = useState(false);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  const _title = title || siteTitle;
  const _description = description || siteDescription;

  const handleClickOutside = (event) => {
    if (
      btnRef.current &&
      !btnRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setMenuOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`page ${className}`}>
      <Helmet
        bodyAttributes={{
          class: classNames({
            opened: menuOpened,
          }),
        }}
      >
        <html lang="en" />
        <title>{_title}</title>
        <meta name="description" content={_description} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Žilvino Reisai",
            image: "https://www.zilvinoreisai.lt/img/cover.jpg",
            "@id": "",
            url: "https://www.zilvinoreisai.lt",
            telephone: "+447872137294",
            address: {
              "@type": "PostalAddress",
              streetAddress: "46 Dore Cl, Northampton",
              addressLocality: "Northamptonshire",
              postalCode: "NN3 8NJ",
              addressCountry: "GB",
            },
            sameAs: [
              "https://www.facebook.com/zilvinoreisai",
              "https://www.zilvinoreisai.lt",
            ],
          })}
        </script>
      </Helmet>
      <div className="page__header">
        <Header
          menuRef={menuRef}
          btnRef={btnRef}
          menuOpened={menuOpened}
          setMenuOpened={setMenuOpened}
          withBackground={withBackground}
        />
      </div>
      <div className="page__body">{children}</div>
      <div className="page__footer">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
