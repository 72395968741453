import classNames from "classnames";
import React from "react";

export function FormRadio({
  label,
  type = "radio",
  name = "fromDate",
  id,
  disabled,
  active,
  fieldValue,
  error,
  ...otherProps
}) {
  return (
    <div
      className={classNames({
        "input-disabled": disabled,
      })}
    >
      <input
        className={classNames("fancy-radio__radio", {
          "have-error": error,
        })}
        type={type}
        disabled={disabled}
        value={fieldValue}
        name={name}
        id={id}
        {...otherProps}
      />
      <label className="fancy-radio__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
